import React from 'react';
import '../App.css';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";

export const NavBar = () =>(
        <div className = "NavBar">
            <ul>
                <li><Link to='/'>Home</Link></li>
                <li><Link to='/about'>About the Website</Link></li>
                <li><Link to='/aboutme'>About Me</Link></li> 
            </ul>     
        </div>
)