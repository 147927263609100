import React from 'react'; 
import '../App.css'

const Home = () => {
    return (
    <div >
          <h1 className = "welcomeTitle">Welcome to my Website</h1>
    </div>
    );
}

export default Home;