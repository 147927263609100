import React from 'react';
import '../App.css';
import linkedin from '../img/linkedin.png';
import instagram from '../img/instagram.png';
import github from '../img/github.png';

export const Social = () =>(
        <div className = "social">
            <ul>
                <li><a href = "https://www.linkedin.com/in/kai-engwall-6ab6921a5/" target="_blank" rel="noreferrer"><img src = {linkedin} alt = 'linkedin'/></a ></li>
                <li><a href = "https://github.com/kengwall8" target="_blank" rel="noreferrer"><img src = {github} alt = 'github'/></a ></li>
                <li><a href ="https://www.instagram.com/kai.m.e/" target="_blank" rel="noreferrer"><img src = {instagram} alt = 'instagram'/></a></li>
                <li id="contact"><a href = "mailto:kengwall@stevens.edu" target="_blank" rel="noreferrer">Contact Me</a ></li>
            </ul>     
        </div>
)