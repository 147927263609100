import React from 'react';
import '../App.css';

export const Border = () =>(
    <div>
        <div id="left"></div>
        <div id="right"></div>
        <div id="top"></div>
        <div id="bottom"></div>
    </div>
)