import React from "react";
import "../App.css";

export const AboutMe = () => (
  <div className='about'>
  <div className='about-text'>
    <h2>About Me</h2>
    <p>
      Hi! My name is Kai Engwall and I'm a junior studying Computer Science at
      Stevens Institute of Technology in Hoboken, NJ. Some languages that I use
      or have used in the past include C++, Java, Python, JavaScript, and C#.
    </p>
  </div>
  </div>
);
