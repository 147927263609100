import React from "react";
import "../App.css";

export const AboutWebsite = () => (
  <div className = 'about'>
    <div className="about-text">
      <h2>About This Website</h2>
      <p>
        I started this website January 2021, as a side project to learn about
        many areas in web development. More specifically, I chose to create this
        website in an effort to learn more about ReactJS.
      </p>
    </div>
  </div>
);

