import "./App.css";
import { NavBar } from "./components/NavBar";
import { Border } from "./components/Border";
import { AboutMe } from "./components/AboutMe";
import { AboutWebsite } from "./components/AboutWebsite";
import Home from "./components/Home";
import {Social} from './components/Social';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";

function App() {
  return (
    <Router>
      <div>
        <NavBar />
        <div className="main">
          <Route exact path="/" component={Home} />
          <Route exact path="/aboutme" component={AboutMe} />
          <Route exact path="/about" component={AboutWebsite} />
        </div>
        <Social/>
        <Border />
      </div>
    </Router>
  );
}

export default App;
